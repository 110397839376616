import Swiper from "swiper";
import {
  Mousewheel,
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

class HeroIntoSlider extends HTMLElement {
  constructor() {
    super();
    this.autoPlaySpeed = Number(this.dataset.autoplaySpeed) || 0;
    this.isLoop = this.dataset.loopSwiper === "true" ? true : false;

    this.heroImageSwiper = null;
    this.heroOverlaySwiper = null;

    this.imageSwiperParams = {
      modules: [Navigation, Mousewheel, Autoplay, Pagination],
      direction: "horizontal",
      slidesPerView: 1,
      effect: "slide",
      loop: this.isLoop,
      speed: 800,
      navigation: {
        nextEl: this.querySelector(".swiper-button-next"),
        prevEl: this.querySelector(".swiper-button-prev"),
      },
      mousewheel: {
        forceToAxis: true,
      },
      pagination: {
        el: this.querySelector(".swiper-pagination"),
        type: "progressbar",
      },
      autoplay:
        this.autoPlaySpeed > 0
          ? {
              delay: this.autoPlaySpeed,
              disableOnInteraction: false,
              stopOnLastSlide: false,
              pauseOnMouseEnter: false,
            }
          : false,
      on: {
        autoplayTimeLeft(s, time, progress) {
          const progressBar = document.querySelector(
            ".autoplay-progress-bar .progress",
          );
          progressBar.style.width = (1 - progress) * 100 + "%";
        },
      },
    };

    this.overlaySwiperParams = {
      modules: [EffectFade],
      slidesPerView: 1,
      effect: "fade",
      speed: 0,
      loop: this.isLoop,
      simulateTouch: false,
    };
  }
  connectedCallback() {
    this.initSwiper();

    if (this.autoPlaySpeed > 0) {
      // Dispatched events from header navigation, cart drawer and predictive search
      document.addEventListener("activeDropdown", () => {
        this.heroImageSwiper.autoplay.pause();
      });

      document.addEventListener("closedDropdown", () => {
        this.heroImageSwiper.autoplay.resume();
      });
    }
  }

  initSwiper() {
    this.heroImageSliderEl = this.querySelector(".hero-swiper-container-main");
    this.heroImageSwiper = new Swiper(
      this.heroImageSliderEl,
      this.imageSwiperParams,
    );

    this.heroOverlaySliderEl = this.querySelector(
      ".hero-swiper-container-overlay",
    );
    this.heroOverlaySwiper = new Swiper(
      this.heroOverlaySliderEl,
      this.overlaySwiperParams,
    );

    this.heroImageSwiper.on("slideChange", () => {
      this.heroOverlaySwiper.slideTo(this.heroImageSwiper.realIndex);
    });
  }
}

customElements.define("hero-intro-slider", HeroIntoSlider);
